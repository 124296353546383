import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid, Paper, TextField, Container,CssBaseline} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Navbar from "./navbar";
import axios from 'axios';
import config from '../../../config';
import { UserContext } from '../../userContext';
import {useNavigate } from "react-router-dom";
import { Folder } from './interfaces';

// const folders: Folder[] = [
//   {
//     name: 'Folder 1'
//   },
//   {
//     name: 'Folder 2'
//   },
// ];

const apiUrl = config.apiUrl;

const FolderDisplay = () => {
const [selectedFolder, setSelectedFolder] = useState<number | null>(null);
const [folders, setFolders] = useState<Folder[]>([]);
const [isLoading, setIsLoading] = useState(false);
const [error, setError] = useState<string | null>(null); 
const { user } = useContext(UserContext);
const navigate = useNavigate();


//const partnerId = user.profile[0].partner;
const [partnerId, setPartnerId] = useState<number | null>(user?.profile?.[0]?.partner?? null);
const [bucketId, setBucketId] = useState<number | null>();
const [userId, setUserId] = useState<number | null>(user?.id? user.id : null);


useEffect(() => {
  const fetchFolders = async () => {
    try {
      const partnerResponse = await axios.get(`${apiUrl}/api/partner/${partnerId}`);
      if (partnerResponse.status !== 200) {
        throw new Error(`Partner data fetch failed: ${partnerResponse.status}`);
      }

      const bucketId = partnerResponse.data.bucket[0];
      setBucketId(bucketId);
      const bucketResponse = await axios.get(`${apiUrl}/api/bucket/${bucketId}`);
      if (bucketResponse.status !== 200) {
        throw new Error(`Bucket data fetch failed: ${bucketResponse.status}`);
      }

      const folderResponse = await axios.get(`${apiUrl}/api/folder/?bucket=${bucketId}`);
      //console.log(folderResponse)
      // const updatedFolders = await Promise.all(
      //   bucketResponse.data.folder.map(async (folder:any) => {
      //     try {
      //       const folderResponse = await axios.get(`${apiUrl}/api/folder/${folder}`);
      //       if (folderResponse.status !== 200) return null; 

      //       const folderExists = folders.some((existingFolder) => existingFolder.id === folderResponse.data.id);
      //       return folderExists ? folder : folderResponse.data; 
      //     } catch (error) {
      //       console.error("Error fetching folder details:", error);
      //       return null; 
      //     }
      //   })
      // );
      //const filteredFolders = updatedFolders.filter(Boolean);
      setFolders(folderResponse.data); 
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(error.message);
      } else {
        setError('An unexpected error occurred');
      }
    }
  };

  fetchFolders();
}, []); 

const handleFolderClick = (folderId: number, partnerId: number | null, bucketId: number | null) => {
  navigate('/files/', {
    state: { 
      folderId: folderId,  
      partnerId: partnerId,
      bucketId: bucketId,
      userId : userId
    } 
  }); 
  setSelectedFolder(folderId);
};

  return (
    <>
      <CssBaseline />
      <Navbar />
      <Container maxWidth="xl">
        <Box sx={{ flexGrow: 1, padding: 2}}>
          <div role="presentation">
        {/* <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="text.primary" href="/">
            Home
          </Link>
          {/* <Link
            underline="hover"
            color="inherit"
            href="/material-ui/getting-started/installation/"
          >
            Core
          </Link>}
          {<Typography color="text.primary">Breadcrumbs</Typography>}
        </Breadcrumbs> */}
      </div>
      <div role="presentation">
      <Typography variant="h5" sx={{ marginTop:'2%'}} component="h6">
        Welcome {user?.username}!
      </Typography>
      </div>
        <Grid container spacing={2} sx={{ marginTop:'2%'}}>
          {folders.map((folder, index) => (
            <Grid item key={index} onClick={() => handleFolderClick(folder.id, partnerId, bucketId ?? null)} lg={3} xs={12}>
              <Paper
                sx={{
                  padding: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'pointer',
                  backgroundColor: selectedFolder === folder.id ? '#fff' : 'inherit',
                  transition: 'background-color 0.3s',
                  '&:hover': {
                      backgroundColor: '#f0f0f0',
                  },
                }}
              >
                <FolderIcon fontSize="large" sx={{color:'#397B96'}}/>
                <Typography>{folder.name}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
      </Container>
    </>
  );
};

export default FolderDisplay;
