import React, { useState, useEffect, } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; 
import { Button, Box, Grid, Paper, TextField, Container,CssBaseline, Typography, Link, Card,CardContent} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Navbar from "./navbar";
import axios from 'axios';
import config from '../../../config';
import UploadModal from './fileUploadModal';
import DownloadIcon from '@mui/icons-material/Download';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { File, Folder } from './interfaces';

const apiUrl = config.apiUrl;

const FileDisplay = () => {
const [selectedFile, setSelectedFile] = useState<number | null>(null);
const [files, setFiles] = useState<File[]>([]);
const [folder, setFolder] = useState();
const [isLoading, setIsLoading] = useState(false);
const [error, setError] = useState<string | null>(null); 
const [isModalOpen, setIsModalOpen] = useState(false);
const location = useLocation();
const { folderId, partnerId, bucketId, userId} = location.state;
const navigate = useNavigate();

const fetchFiles = async () => {
  try {
    const response = await axios.get(`${apiUrl}/api/file/?folder=${folderId}`);
    const folderResponse = await axios.get(`${apiUrl}/api/folder/${folderId}`);
    setFiles(response.data);
    setFolder(folderResponse.data.name);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      setError(error.message); 
    } else {
      setError('An unexpected error occurred');
    }
  }
}

useEffect(() => {
  fetchFiles();
}, [folderId]);

const handleOpenModal = () => {
  setIsModalOpen(true);
};

const handleCloseModal = () => {
  setIsModalOpen(false);
  fetchFiles();
};

const handleFileClick = (index: number) => {
    setSelectedFile(index);
};

const handleBackClick = () => {
  navigate('/home/', {
    state: { 
      partnerId: partnerId,
      userId: userId
    } 
  }); 
};

  return (
    <>
      <CssBaseline />
      <Navbar />
      <Container maxWidth="xl">
        <Box sx={{ flexGrow: 1, paddingRight: 10}}>
          <Grid container spacing={2} 
            sx={{ 
            justifyContent:'flex-start',
            marginLeft: "3%", 
            marginTop:'2%',
            }}>
            <Button
              startIcon={<ArrowBackIcon />}
              sx={{backgroundColor: '#E5E4E2', color: 'black', '&:hover': { backgroundColor: '#D3D3D3' } }}
              onClick={handleBackClick}
              >
               Go back
            </Button>
          </Grid>
        <Grid container spacing={5} sx={{ marginTop:'0%', marginLeft: "auto"}} direction="row" alignItems="center">
          <Button
            variant="contained"
            startIcon={<CloudUploadIcon />}
            sx={{ marginLeft: "auto", justifyContent:'flex-end', backgroundColor: '#397B96', color: 'white', '&:hover': { backgroundColor: '#0097a7' } }}
            onClick={handleOpenModal}
          >
            Upload file
          </Button>
          <Grid container spacing={2} 
              sx={{ 
              justifyContent:'flex-start',
              marginLeft: "3%", 
              marginTop:'2%',
              }}>
            <FolderIcon fontSize="large" sx={{color:'#397B96', marginRight:2}}/>
            <Typography variant="h5">{folder}</Typography>
          </Grid>
          <Grid container spacing={2} 
              sx={{ 
              justifyContent:'flex-start',
              marginLeft: "3%", 
              marginTop:'2%',
              }}>
              <Typography variant="body2">{files.length} items</Typography> 
          </Grid>
          {files.length > 0 ? files.map((file, index) => (
            <Grid item key={index} onClick={() => handleFileClick(index)} lg={3} xs={12}>
              <Paper
                sx={{
                  padding: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'pointer',
                  backgroundColor: selectedFile === index ? '#fff' : 'inherit',
                  transition: 'background-color 0.3s',
                  '&:hover': {
                      backgroundColor: '#f0f0f0',
                  },
                }}
              >
                <TextSnippetOutlinedIcon fontSize="large" sx={{color:'#397B96'}}/>
                <Typography variant="overline">{file.name}</Typography>
                {/* <Typography>{file.location}</Typography> */}
                <Link href={file.location} sx={{ alignItems:'flex-end', justifyContent:'flex-end' }}>
                  <DownloadIcon 
                  sx={{ marginTop:1, color: 'grey'}}
                  />
                </Link>
              </Paper>
            </Grid>
          )): 
            <Grid item lg={12} xs={12}>
              <Paper
                sx={{
                  padding: 5,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'pointer',
                  backgroundColor: 'inherit',
                  height: '300px'
                }}
              >
                <InsertDriveFileOutlinedIcon fontSize="large"
                 sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'pointer',
                  backgroundColor: 'inherit',
                  color: 'grey',
                  fontSize: 100,
                  margin: 2
                }}
                />
                <Typography variant="h6">No File Uploaded</Typography>
              </Paper>
            </Grid>
          }
        </Grid>
      </Box>
      </Container>
      <UploadModal open={isModalOpen} onClose={handleCloseModal} folderId={folderId} partnerId={partnerId} bucketId={bucketId} userId={userId}/> 
    </>
  );
};

export default FileDisplay;
