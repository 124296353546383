import { createContext, useState } from 'react';

type PartnerProfile = {
    id: number;
    contact_number: string;
    role: string;
    other: string;
    partner: number
  }

type User = {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    profile: PartnerProfile[]
};

type UserWithoutPassword = Omit<User, 'password'>;

interface UserContext {
    user: User | null;
    login: (user: User) => void;
    logout: () => void;
  }

  const UserContext = createContext<UserContext>({
    user: null,
    login: () => {},
    logout: () => {},
  });

const UserProvider = ({ children }: any) => {
    const [user, setUser] = useState<User | null>(localStorage.getItem('user')? JSON.parse(localStorage.getItem('user') || '') : null);
    const login = (userData: User) => {
        const saveData = {
            id: userData.id,
            username: userData.username,
            first_name: userData.first_name,
            last_name: userData.last_name,
            email: userData.email,
            profile: userData.profile
        };
        localStorage.setItem('user', JSON.stringify(saveData));
        setUser(userData);
    };
    const logout = () => {
        localStorage.removeItem('user');
        setUser(null);
    }; 

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };