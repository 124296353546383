import { LockOutlined } from "@mui/icons-material";
import {
  Container,
  CssBaseline,
  Box,
  Avatar,
  Typography,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { useState, useContext} from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import logo from '../makorxLogo.png';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import config from '../config';
import Alert from '@mui/material/Alert';
import { UserContext } from './userContext';

interface LoginFormValues {
  username: string;
  password: string;
}

interface LoginProps {
  onLogin: (token: string) => void;
}

const validationSchema = Yup.object().shape({
  username: Yup.string().required('Required'),
  password: Yup.string().required('Required')
});

const apiUrl = config.apiUrl;

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setError] = useState<string | null>(null); 
  const { login } = useContext(UserContext);

  const navigate = useNavigate();
  
  // const handleLogin = (values: LoginFormValues, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
  //   axios.post('/api/login', values)
  //     .then(response => {
  //       console.log('Login successful', response);
  //       navigate('/home'); 
  //     })
  //     .catch(error => {
  //       console.error('Login error', error);
  //     })
  //     .finally(() => {
  //       setSubmitting(false);  
  //     });
  // };
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const onLogin = (token: string) => {
    navigate('/home');
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        console.log(values)
        const response = await axios.post(`${apiUrl}/api-token-auth/`, values);
        console.log(response.data)
        if (response.status === 200) {
          const token = response.data.token;
          const userResponse = await axios.get(`${apiUrl}/api/user/?expand=profile`,token);
          if (userResponse.status === 200) {
            const user = userResponse.data[0];
            login(user);
            onLogin(token);
          }
        }
      } catch (error: any) {
        const errorMessage = error.response.data.non_field_errors[0];
        setError(errorMessage)
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Container maxWidth="xs">
          <CssBaseline />
          <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box
            component="img"
            sx={{
                height: 233,
                width: 350,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
                marginTop: 'auto',
            }}
            alt="MakoRx Logo"
            src={logo}
            />
            <Typography variant="h5"> Partner Login</Typography>
            {errorMessage && (
              <Alert severity="error" sx={{ width: "100%", mt: 2 }}>
                {errorMessage}
              </Alert>
            )}
            <Box sx={{ mt: 1 }}>
              <TextField
                fullWidth
                id="username"
                name="username"
                label="Username"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={formik.touched.username && Boolean(formik.errors.username)}
                helperText={formik.touched.username && formik.errors.username}
                margin="normal"
              />
              <TextField
                fullWidth
                id="password"
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                margin="normal"
              />
              <Grid container justifyContent="left">
                <Grid item>
                  <Typography style={{ fontSize: '1rem' ,color: '#397B96'}}>
                      <Link to="/reset" style={{ fontSize: '1rem' ,color: '#397B96', marginLeft:'auto'}}>Forgot Password?</Link>
                  </Typography>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2, backgroundColor: '#397B96', color: 'white', '&:hover': { backgroundColor: '#0097a7' } }}
              >
                Login
              </Button>
              <Grid container justifyContent="center">
                <Grid item>
                  {/* <Typography style={{ fontSize: '1rem' ,color: '#397B96'}}>Don't have an account? Please contact MakoRx Admin
                      <Link to="/register" style={{ fontSize: '1rem' ,color: '#397B96', marginLeft:'10px'}}>Register</Link>
                  </Typography> */}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </form>      
    </>
  );
};

export default Login;